<template>
  <app-card class="status-card card-success" v-if="fromUpload">
    <div class="status-content">
      <div class="status-icon">
        <i class="fas fa-check"></i>
      </div>

      <div>
        <h2 class="title">Ön Kaydınız Alınmıştır</h2>
        <p style="font-weight: bold; font-size:22px; color: #000;">
          Tebrikler<br />
          İhracatçı ailemize katılmaya bir adım daha yaklaştınız
        </p>
        <p>
          Bundan sonraki süreçte başvuru durumunuzu aşağıdaki başvuru kodunuzu
          kullanarak takip edebilirsiniz. Ayrıca başvurunuz ile ilgili
          güncellemeler e-posta adresinize gönderilecektir.
        </p>

        <div class="application-code">
          <label>BAŞVURU KODU</label>
          <div class="code">{{ applicationData.approvalRequest?.code }}</div>
        </div>

        <div style="padding-top:70px;">
          <h4>Şimdi ne yapmalıyım?</h4>
        </div>
        <p>
          Şu aşamada yapmanız gereken bir işlem bulunmamaktadır. Başvurunuz ve
          yüklediğiniz dosyalar üyelik şubesi tarafından incelenerek üyeliğinize
          aykırı bir durum olmaması halinde bir sonraki adıma geçilecektir.
        </p>
      </div>
    </div>
  </app-card>
  <app-card-wizard :title="status.title" v-else>
    <p>{{ status.description }}</p>
    <app-value-outlet
      label="Başvuru No"
      :value="this.ar.code"
    ></app-value-outlet>
    <app-value-outlet
      label="İlgili Kurum"
      :value="this.ar.whereRequestAwait"
    ></app-value-outlet>
    <app-wizard-buttons
      :next-button-visible="false"
      :prev-button-visible="true"
      prev-button-text="Ana Sayfaya Geri Dön"
      @prev-button-click="goTo('/')"
    ></app-wizard-buttons>
  </app-card-wizard>
</template>
<script>
import AppValueOutlet from "../components/ValueOutlet.vue";

export default {
  components: {
    AppValueOutlet
  },
  data() {
    return {};
  },
  computed: {
    status() {
      let title = "";
      let description = "";

      switch (this.ar.statusId) {
        case 2103:
          title = "Başvuru İnceleniyor";
          description =
            "Başvurunuz şu anda uzman incelemesi aşamasındandır. İncelemeler tamamlandığına e-posta ile bilgilendirme yapılacaktır.";
          break;
        case 2104:
          title = "Evrakların Yüklenmesi Bekleniyor";
          description =
            "Başvuru esnasında gerekli evrakların yüklenmesi gerekiyor.";
          break;
        case 2105:
          if (this.ar.isPaymentReceived && !this.ar.isCargoReceived) {
            title = "Kargo Bekleniyor";
            description =
              "Başvurunuzun onaylanabilmesi için başvuru esnasında yüklediğiniz evrakların orijinallerinin ilgili birliğe kargo ile gönderilmesi gerekmektedir.";
          } else if (!this.ar.isPaymentReceived && this.ar.isCargoReceived) {
            title = "Ödeme Bekleniyor";
            description =
              "Başvurunuzun onaylanabilmesi için giriş aidatının ilgili birliğe ait banka hesap numaralarına yatırılmış olması gerekmektedir.";
          } else {
            title = "Kargo ve Ödeme Bekleniyor";
            description =
              "Başvurunuzun onaylanabilmesi için başvuru esnasında yüklediğiniz evrakların orijinallerinin ilgili birliğe kargo ile gönderilmesi ve birlik üyeliği giriş aidatının ilgili birliğe ait banka hesap numaralarına yatırılmış olması gerekmektedir.";
          }

          // WaitingPaymentAndOriginalDocuments
          break;
        case 2108:
          title = "Başvuru Onaylandı";
          description =
            "Başvurunuz onaylanmıştır. İhracatçı ailemize hoş geldiniz.";
          // Approved
          break;
        case 2109:
          title = "Başvuru Reddedildi";
          description = "Başvurunuz reddedilmiştir.";
          // Rejected
          break;
      }

      return {
        title,
        description
      };
    },
    ar() {
      return this.applicationData.approvalRequest;
    },
    fromUpload() {
      return this.applicationData.previousStep.path.startsWith("/upload/");
    }
  },
  beforeMount() {
    this.updateApplicationData({
      fromReview: true
    });
  }
};
</script>
<style scoped lang="scss">
@import "../scss/_variables";

.application-code {
  background: #f7f7f7;
  color: #000;
  padding: 30px;
  margin: 40px 0;
  .code {
    font-size: 36px;
    line-height: 36px;
    font-weight: $font-weight-extra-bold;
  }

  label {
    font-size: 14px;
    font-weight: normal;
    line-height: 14px;
  }
}

.card-primary {
  color: rgba(255, 255, 255, 0.8);

  strong,
  b {
    color: #fff;
  }

  .title {
    color: #fff;
  }
}

.card-success {
  //background: lighten($info, 10%);
  //color: #fff;
}

.status-content {
  display: flex;
  width: 100%;
  margin: 0 auto;
  padding: 50px;
  //color: #fff;

  .title {
    //color: #fff;
    margin-bottom: 30px;
    font-size: 2.5rem;
    color: $primary;
  }

  .status-icon {
    i {
      font-size: 3rem;
      color: $primary;
      margin-right: 40px;
    }
  }
}
</style>
